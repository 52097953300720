import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const resetForm = () => ({
  name: "",
  email: "",
  phone: "",
  newsletter: true,
});

const resetErrorMessages = () => ({
  name: "",
  email: "",
  message: "",
});

const ContactForm = ({ submissionStatus, setSubmissionStatus }) => {
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState(resetErrorMessages());

  const handleInputChange = (e) => {
    e.persist();
    resetErrorMessages();
    const { name, value, type, checked } = e.target;
    if (errorMessage[name]) {
      setErrorMessage((currentStates) => ({
        ...currentStates,
        [name]: type === "checkbox" ? true : "",
      }));
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const encodeData = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const sendFormToNetlify = async (e) => {
    e.preventDefault();
    setSubmissionStatus("submit-processing");

    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeData({
        "form-name": form.getAttribute("name"),
        ...formValues,
      }),
    })
      .then(() => {
        setSubmissionStatus("submit-complete");
        resetForm();
      })
      .catch((error) => alert(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValues.name) {
      setErrorMessage((currentStates) => ({
        ...currentStates,
        name: "(Please provide a name)",
      }));
    } else if (!formValues.email && !formValues.phone) {
      setErrorMessage((currentStates) => ({
        ...currentStates,
        email:
          "(Please provide at least one point of contact, email address or phone number so we can get back to you)",
      }));
    } else {
      sendFormToNetlify(e).then(() =>
        setTimeout(() => {
          resetForm();
        }, 1750)
      );
    }
  };

  useEffect(() => {
    if (formValues.name.length > 0) {
      if (formValues.email.length > 0 || formValues.phone.length > 0) {
        setSubmissionStatus("ready-to-submit");
      } else {
        setSubmissionStatus("not-ready-to-submit");
      }
    } else {
      setSubmissionStatus("not-ready-to-submit");
    }
  }, [formValues]);

  return (
    <Form
      name="how-can-we-help"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="how-can-we-help" />
      <div hidden>
        <label>
          Don&apos;t fill this out:
          <input name="bot-field" onChange={handleInputChange} />
        </label>
      </div>
      <Fields>
        <FormItem>
          <label htmlFor="name">Name</label>
          <input
            aria-label="Enter your name"
            type="text"
            id="name"
            className="name-contact"
            name="name"
            required
            placeholder="Enter Your Name"
            onChange={handleInputChange}
            value={formValues.name}
          />
          {errorMessage.name && <span>{errorMessage.name}</span>}
        </FormItem>
        <FormItem>
          <label htmlFor="email">E-mail</label>
          <input
            aria-label="Enter your email"
            type="email"
            id="email"
            className="email-contact"
            name="email"
            placeholder="Enter Your Email"
            onChange={handleInputChange}
            value={formValues.email}
          />
          {errorMessage.email && <span>{errorMessage.email}</span>}
        </FormItem>
        <FormItem>
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="phone-contact"
            pattern="[0-9]*"
            inputmode="numeric"
            placeholder="Enter Your Phone Number"
            onChange={handleInputChange}
            value={formValues.phone}
          />
        </FormItem>
      </Fields>
      <Buttons>
        <FormItem>
          <label htmlFor="newsletter" style={{ display: "none" }}>
            Agree to receive news and updates from Waterstone Clinic:
          </label>
          <FormCheck>
            <FormCheckbox
              checked={formValues.newsletter}
              id="newsletter"
              name="newsletter"
              onChange={handleInputChange}
              type="checkbox"
            />
            <FormTerms>
              I agree to receive news and updates from Waterstone Clinic.
            </FormTerms>
          </FormCheck>
        </FormItem>
        <ContactSubmitButton
          id="contact-form-submit"
          className="contact-form-submit"
          type="submit"
          disabled={submissionStatus !== "ready-to-submit"}
          whileHover={{
            scale: 1.025,
          }}
          whileTap={{ scale: 0.9 }}
        >
          <span>
            {submissionStatus === "submit-processing" ? "Submitting" : "Submit"}
          </span>
        </ContactSubmitButton>
      </Buttons>
    </Form>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-between;
  @media only screen and (min-width: 1024px) {
    gap: 2rem;
    flex-direction: row;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 3rem;
  }
`;

const Form = styled.form`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  label,
  input,
  span,
  p,
  select,
  textarea {
    color: var(--wc-dark-text-color);
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  label {
    padding-left: 1.25rem;
    display: flex;
    gap: 0.5rem;
    span {
      font-size: 0.625rem;
      color: var(--wc-highlight-color);
    }
  }
  input,
  select,
  textarea {
    background: none;
    font-family: "Mallory Book", sans-serif;
  }
  input,
  select,
  textarea {
    border: 1px solid var(--wc-light-text-color);
    border-radius: 4px;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    resize: none;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  input,
  select,
  textarea {
    &:focus {
      outline: none;
      border: 1px solid var(--wc-highlight-color);
      color: var(--wc-highlight-color);
    }
    ::placeholder {
      color: var(--wc-light-text-color);
      font-style: italic;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
  select::-ms-expand {
    display: none;
  }
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  span {
    color: var(--wc-highlight-color);
    font-size: 12px;
  }
`;

const ContactSubmitButton = styled(motion.button)`
  background: var(--wc-highlight-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  border: 1px solid var(--wc-highlight-color);
  cursor: ${({ disabled }) => (disabled === true ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled === true ? "0.5" : "1")};
  align-self: flex-end;
  span {
    font-size: 1rem;
    line-height: 1.75rem;
    color: white;
    text-transform: uppercase;
  }
  &:hover {
    background: transparent;
    transition: background ease-in-out 0.25s;
    span {
      color: var(--wc-highlight-color);
      transition: color ease-in-out 0.25s;
    }
  }
`;

const FormCheck = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  position: relative;
`;

export const FormCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--wc-highlight-color);
  cursor: pointer;
  height: 1.5rem;
  outline: none;
  padding: 0.625rem !important;
  position: relative;
  width: 1.5rem;
  max-width: 1.5rem;
  transition: 0.5s;
  &:checked {
    border: 1px solid var(--wc-highlight-color);
  }
  &:checked:before {
    color: var(--wc-highlight-color);
    content: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 3L6.33333 10L3 7.04444' stroke='%23FD7F46' stroke-width='3' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    width: 1rem;
  }
  &:focus {
    border: 0.125rem solid var(--wc-highlight-color);
  }
`;

export const FormTerms = styled.span`
  color: var(--wc-dark-text-color) !important;
  font-family: "Mallory", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
`;

export default ContactForm;
