import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

const ModalContent = (props) => {
  return ReactDOM.createPortal(
    <ModalCover
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}
    >
      <ModalArea
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={props.onClickOutside}
      >
        <ModalBody ref={props.modalRef} onClick={(e) => e.stopPropagation()}>
          <ModalClose
            aria-label="Close Contact Form"
            aria-labelledby="close-contact-form"
            onClick={props.closeModal}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 1L1 10.9999" stroke="#56514F" stroke-width="2" />
              <path d="M11 11L1 1.00008" stroke="#56514F" stroke-width="2" />
            </svg>
          </ModalClose>
          {props.content}
        </ModalBody>
      </ModalArea>
    </ModalCover>,
    document.body
  );
};

const ModalClose = styled.button`
  background: var(--wc-light-background);
  align-items: center;
  border: 0;
  border-radius: 4px;
  box-shadow: 0;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  transition: 0.2s;
  width: 1.5rem;
  z-index: 20;
  &:hover {
    transform: scale3d(1.075, 1.075, 1.075);
    transition: transform 200ms ease-in-out;
  }
  svg {
    path {
      fill: var(--wc-dark-text-color);
      stroke: var(--wc-dark-text-color);
    }
  }
`;

const ModalBody = styled.div`
  height: auto;
  width: 100%;
  max-height: 100%;
  position: relative;
  width: 100%;
  max-width: 940px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media only screen and (min-width: 1024px) {
    min-width: 940px;
  }
`;

const ModalArea = styled(motion.aside)`
  background-color: transparent;
  box-sizing: border-box;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ModalCover = styled.div`
  background: rgba(86, 81, 79, 0.8);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  max-width: 100vw;
  padding: 1rem 0.25rem;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 10;
`;

export default ModalContent;
