import * as React from "react";
const SvgPhoneIconDark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g fill="#42495c" fillRule="evenodd">
      <path d="M16 10h-2c0-2.206-1.794-4-4-4V4c3.309 0 6 2.691 6 6" />
      <path d="M20 10h-2c0-4.411-3.589-8-8-8V0c5.514 0 10 4.486 10 10M10 8v2h2a2 2 0 0 0-2-2" />
      <path d="M2 3v5c0 5.514 4.486 10 10 10h5.001L17 14h-4v1a1 1 0 0 1-1 1c-4.488 0-7.928-3.433-8-7.984A1 1 0 0 1 5 7h1V3H2zm15 17h-5C5.383 20 0 14.617 0 8V3c0-1.103.897-2 2-2h4c1.103 0 2 .897 2 2v4c0 1.072-.848 1.95-1.908 1.998.437 2.584 2.367 4.52 4.909 4.924A2.003 2.003 0 0 1 13 12h4c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2z" />
    </g>
  </svg>
);
export default SvgPhoneIconDark;
