import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddToCalendar, Button } from "../../../components";

const AnonymousForm = ({
  submissionStatus,
  setSubmissionStatus,
  calendarLink,
}) => {
  const [formValues, setFormValues] = useState({
    email: "",
    webinarQuestion: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  console.log(submissionStatus);

  const resetForm = () => {
    setFormValues({
      email: "",
      webinarQuestion: "",
    });
    setErrorMessage("");
  };

  const handleInputChange = (e) => {
    e.persist();
    setErrorMessage("");
    const { name, value } = e.target;
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formValues.email && formValues.email.includes("@")) {
      setSubmissionStatus("ready-to-submit");
    } else {
      setSubmissionStatus("not-ready-to-submit");
    }
  }, [formValues.email]);

  const encodeData = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const sendFormToNetlify = async (e) => {
    e.preventDefault();

    setSubmissionStatus("submit-processing");

    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeData({
        "form-name": form.getAttribute("name"),
        ...formValues,
      }),
    })
      .then(() => {
        setSubmissionStatus("submit-complete");
        resetForm();
      })
      .catch((error) => alert(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValues.email && formValues.email.includes("@")) {
      sendFormToNetlify(e).then(() =>
        setTimeout(() => {
          resetForm();
        }, 1750)
      );
    } else {
      setErrorMessage("Please provide a valid email address.");
      setSubmissionStatus("not-ready-to-submit");
    }
  };

  return (
    <Form
      name="webinar-interest"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="webinar-interest" />
      <div hidden>
        <label>
          Don&apos;t fill this out:
          <input name="bot-field" onChange={handleInputChange} />
        </label>
      </div>
      <FormItem>
        <label>Email:</label>
        <label
          htmlFor="email"
          style={{ visibility: "hidden", height: 0, marginTop: "-0.5rem" }}
        >
          Email
        </label>
        <input
          aria-label="Enter Your Email"
          type="text"
          id="email"
          className="email-webinar"
          name="email"
          required
          placeholder="Enter Your Email"
          onChange={handleInputChange}
          value={formValues.email}
        />
      </FormItem>
      <FormItem>
        <label>Ask your anonymous question: (Optional)</label>
        <label
          htmlFor="webinarQuestion"
          style={{ visibility: "hidden", height: 0, marginTop: "-0.5rem" }}
        >
          Question for Webinar
        </label>
        <input
          aria-label="Enter Your Question"
          type="text"
          id="webinarQuestion"
          className="question-webinar"
          name="webinarQuestion"
          placeholder="Enter Your Question"
          onChange={handleInputChange}
          value={formValues.webinarQuestion}
        />
      </FormItem>
      <Options>
        <AddToCalendar calendarLink={calendarLink} />
        <Button
          id="webinar-interest-submit"
          type="submit"
          disabled={submissionStatus !== "ready-to-submit"}
          style={{
            textTransform: "uppercase",
            fontWeight: "400",
            marginLeft: "auto",
            width: "300px",
          }}
        >
          {submissionStatus === "submit-processing"
            ? "Submitting"
            : "Register Your Interest"}
        </Button>
      </Options>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  label,
  input,
  span,
  p,
  select,
  textarea {
    color: var(--wc-dark-text-color);
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  label {
    font-size: 0.875rem;
    display: flex;
    gap: 0.5rem;
    span {
      font-size: 0.625rem;
      color: var(--wc-highlight-color);
    }
  }
  input,
  select,
  textarea {
    background: none;
    font-family: "Mallory Book", sans-serif;
  }
  input,
  select,
  textarea {
    border: 1px solid var(--wc-light-text-color);
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    resize: none;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  input,
  select,
  textarea {
    &:focus {
      outline: none;
      border: 1px solid var(--wc-highlight-color);
      color: var(--wc-highlight-color);
    }
    ::placeholder {
      color: var(--wc-light-text-color);
      font-style: italic;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
  select::-ms-expand {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    label {
      padding-left: 1.25rem;
    }
    input {
      padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    }
  }
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  span {
    color: var(--wc-highlight-color);
    font-size: 12px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0 2.625rem 0;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
    gap: 3rem;
  }
`;

export default AnonymousForm;
