import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../hooks";
import { HeroBubble } from "../../assets/svgs";
import RenderWhenReady from "../RenderWhenReady";

const Hero = ({ introduction, image, mobileImage }) => {
  const { windowWidth } = useWindowSize();

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.75,
        staggerChildren: 0.75,
        duration: 0.75,
      },
    },
  };

  const moveUp = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <Container>
      <RenderWhenReady>
        {windowWidth < 1024 ? (
          <BackgroundImage
            image={getImage(mobileImage)}
            alt="Person holding mug and looking contently out the window."
          />
        ) : (
          <BackgroundImage
            image={getImage(image)}
            alt="Person holding mug and looking contently out the window."
          />
        )}
      </RenderWhenReady>
      <BackgroundOverlay />
      <InnerContainer
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={container}
      >
        <Content variants={moveUp}>
          <HeroBubble />
          <p
            dangerouslySetInnerHTML={{
              __html: introduction,
            }}
          />
        </Content>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  position: relative;
  justify-content: flex-end;
  .gatsby-image-wrapper {
    position: absolute;
  }
`;

const InnerContainer = styled(motion.div)`
  grid-column: 2 / 12;
  z-index: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  @media only screen and (min-width: 1920px) {
    grid-column: 3 / 11;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const BackgroundOverlay = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(86, 81, 79, 1) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60%;
  width: 100%;
  z-index: 0;
  @media only screen and (min-width: 1024px) {
    height: 20%;
  }
`;

const Content = styled(motion.div)`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: flex-end;
  padding: 1.5rem 0;
  position: relative;
  z-index: 6;
  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--wc-light-text-color);
    position: relative;
    text-align: right;
  }
  @media only screen and (min-width: 640px) {
    gap: 24px;
    padding: 3rem 0;
    svg {
      height: 40vh;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding: 6rem 0;
    p {
      color: #ffffff;
    }
  }
`;

export default Hero;
