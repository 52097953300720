import * as React from "react";
const SvgFacebookF = (props) => (
  <svg
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 24V12H0V8h4V5.852C4 1.785 5.981 0 9.361 0c1.619 0 2.475.12 2.88.175V4H9.936C8.501 4 8 4.757 8 6.291V8h4.205l-.571 4H8v12H4Z" />
  </svg>
);
export default SvgFacebookF;
