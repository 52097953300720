import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../../components";
import {
  ReasonOne,
  ReasonTwo,
  ReasonThree,
  ReasonFour,
  ReasonFive,
} from "./Reasons";
import { hexToRGB, scrollToAnchor } from "../../utils";

const WhyChoose = ({ reasons }) => {
  return (
    <Container>
      <Title>Why Choose Waterstone Clinic</Title>
      {reasons && reasons.length > 1 && (
        <Grid>
          {reasons.map((reason, index) => (
              <GridItem key={index}>
                <AnimatePresence>
                  <IconContainer
                    initial="rest"
                    whileInView="hover"
                    animate="rest"
                    color="#DCD3CB"
                  >
                    {index === 0 ? (
                      <ReasonOne style={{ top: "52.5%" }} />
                    ) : index === 1 ? (
                      <ReasonTwo style={{ top: "47.5%" }} />
                    ) : index === 2 ? (
                      <ReasonThree style={{ top: "45%" }} />
                    ) : index === 3 ? (
                      <ReasonFour style={{ top: "52.5%" }} />
                    ) : (
                      <ReasonFive style={{ top: "45%" }} />
                    )}
                  </IconContainer>
                </AnimatePresence>
                <h5 dangerouslySetInnerHTML={{ __html: reason.title }} />
                <p dangerouslySetInnerHTML={{ __html: reason.description }} />
              </GridItem>
            ))}
        </Grid>
      )}
      <Button
        id="why-choose-waterstone-contact"
        whileHover={{
          scale: 1.025,
        }}
        whileTap={{ scale: 0.9 }}
        onClick={(e) => {
          e.preventDefault();
          scrollToAnchor("contact-form", {
            duration: 1200,
            offset: -160,
          });
        }}
        style={{
          textTransform: "uppercase",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        Contact Us
      </Button>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 1rem 1rem 3rem 1rem;
  @media only screen and (min-width: 1024px) {
    gap: 6rem;
    margin: 6rem 3rem;
  }
`;

const Title = styled.h2`
  color: var(--wc-highlight-color);
  font-family: "Mallory", sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  gap: 3rem;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`;

const GridItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-height: 200px;
  text-align: center;
  max-width: 230px;
  h5 {
    font-family: "Mallory", sans-serif;
    color: var(--wc-highlight-color);
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  p {
    font-family: "Mallory", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;


const IconContainer = styled(motion.div)`
border-radius: 50%;
  background-color: ${({ color }) => hexToRGB({ color: color, alpha: 0.3 })};
  width: 124px;
  height: 124px;
  position: relative;
  svg {
    position: absolute;
    height: 70%;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }`;

export default WhyChoose;
