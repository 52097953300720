import * as React from "react";
import { motion } from "framer-motion";

const draw = {
  rest: { pathLength: 1, opacity: 1 },
  hover: {
    pathLength: [1, 0, 1],
    opacity: [1, 0, 1],
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};
const SvgReasonFive = (props) => (
  <svg viewBox="0 0 94 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.444 48.0927C36.2446 46.0346 39.4424 42.3464 39.4424 38.4238V30.7213" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.8751 34.9087V38.4237C18.8751 42.4787 21.9438 46.0651 24.8735 48.0926" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3014 50.7315L12.5789 54.2262C7.9212 55.8971 3.83952 61.1543 2.89607 64.6184C1.07868 71.3021 2.58821 82 2.58821 82" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.195 48.5511C36.195 53.8288 29.1539 60.4003 29.1539 60.4003C29.1539 60.4003 22.2915 54.6642 22.3014 46.5134" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.6931 51.6077V29.7636C43.6931 21.4294 37.1287 14.6948 29.0051 14.6948C20.8814 14.6948 14.317 21.4294 14.317 29.7636V53.0545" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.1674 20.0233C42.1537 29.193 18.8156 28.7447 18.8156 28.7447" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.195 46.5134V49.8858L45.9175 53.3805C50.5752 55.0514 54.6569 60.3086 55.6003 63.7727C57.4177 70.4564 55.9082 81.1543 55.9082 81.1543" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<motion.g
        variants={draw}>
<path d="M75.1348 16.0703L78.561 8.15385C78.561 8.15385 75.1348 2 64.8462 2C54.5576 2 51.1313 8.15385 51.1313 8.15385L54.5576 16.0703" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.8463 6.39136V13.4316" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M61.4201 9.9165H68.2726" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.5659 38.923C57.7654 36.865 54.5676 33.1767 54.5676 29.2542V25.7391" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M75.1346 25.7391V29.2542C75.1346 33.3092 72.0659 36.8955 69.1362 38.923" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M75.1347 21.3377C66.5641 21.3377 64.856 17.8226 64.856 17.8226C64.856 17.8226 63.1379 21.3377 54.5773 21.3377" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.2772 39.7993V21.3377C50.2772 18.4646 51.0319 15.7544 52.3528 13.4213" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M77.3394 13.4314C78.6602 15.7646 79.415 18.4645 79.415 21.3479V39.8094" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M71.6985 37.1707V41.5619L81.421 45.0565C86.0787 46.7275 90.1604 51.9847 91.1038 55.4488C92.9212 62.1324 91.4117 81.1645 91.4117 81.1645" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.2712 45.0565L57.9937 41.5619V37.1707" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M57.9937 41.5619C57.9937 46.8395 64.8461 51.2308 64.8461 51.2308C64.8461 51.2308 71.6985 46.8395 71.6985 41.5619" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</motion.g>
</svg>
);
export default SvgReasonFive;
