import React from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import DropdownNavigation from "./DropdownNavigation";
import { scrollToAnchor } from "../../utils";
import {
  WaterstoneClinicLogo,
  BookIconBlue,
  FacebookF,
  Youtube,
  Instagram,
  PhoneIconDark,
} from "../../assets/svgs";

const Navigation = ({ expanded, setExpanded }) => {
  const dropdownToggle = () => {
    setExpanded(!expanded);
  };

  return (  
    <Header>
      <Container>
        <LogoContainer href={`https://waterstoneclinic.ie/`}>
          <WaterstoneClinicLogo />
        </LogoContainer>
        <Links>
          <ul>
            <Link>
              <BookIconBlue />
              <button
                id="navigation-contact-us"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToAnchor("contact-form", {
                    duration: 1200,
                    offset: -160,
                  });
                }}
              >
                Contact Us
              </button>
            </Link>
            <Link>
              <a
                href={`https://waterstoneclinic.ie/fertility-tests/`}
                id="navigation-fertility-tests"
              >
                Fertility Tests
              </a>
            </Link>
            <Link>
              <a
                href={`https://waterstoneclinic.ie/treatments-care/`}
                id="navigation-treatments"
              >
                Treatments
              </a>
            </Link>
            <Link>
              <a
                href={`https://waterstoneclinic.ie/paying-for-fertility-treatment/our-price-list/`}
                id="navigation-costs"
              >
                Costs
              </a>
            </Link>
            <Link>
              <a
                href={`https://waterstoneclinic.ie/nationwide-clinics-2/`}
                id="navigation-clinics"
              >
                Clinics
              </a>
            </Link>
          </ul>
          <Socials>
            <Social
              href="https://www.facebook.com/waterstoneclinicireland/"
              target="_blank"
              rel="noreferrer"
              id="navigation-facebook"
            >
              <FacebookF />
            </Social>
            <Social
              href="https://www.youtube.com/channel/UCxW98MpTKUeNZiheUCu9MmQ/videos?themeRefresh=1"
              target="_blank"
              rel="noreferrer"
              id="navigation-youtube"
            >
              <Youtube />
            </Social>
            <Social
              href="https://www.instagram.com/waterstoneclinicireland/"
              target="_blank"
              rel="noreferrer"
              id="navigation-instagram"
            >
              <Instagram />
            </Social>
          </Socials>
        </Links>
        <MobileLinks>
          <PhoneLink href="tel:+353818333310">
            <PhoneIconDark />
          </PhoneLink>
          <BurgerContainer onClick={dropdownToggle}>
            <Burger expanded={expanded}>
              <BurgerBar expanded={expanded} />
              <BurgerBar expanded={expanded} />
              <BurgerBar expanded={expanded} />
            </Burger>
          </BurgerContainer>
        </MobileLinks>
      </Container>
      <AnimatePresence>{expanded && <DropdownNavigation />}</AnimatePresence>
    </Header>
  );
};

const Header = styled.header`
  width: 100%;
  padding: 1.25rem 0;
  position: relative;
  background: #ffffff;
  @media only screen and (min-width: 1024px) {
    padding: 1.5625rem 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  padding: 0 0.9375rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  align-items: center;
`;

const LogoContainer = styled.a`
  grid-column: 1 / span 8;
  padding: 0 0.9375rem;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    max-height: 1.3125rem;
    height: 100%;
    width: auto;
  }
  @media only screen and (min-width: 1024px) {
    grid-column: 1 / span 4;
  }
`;

const Links = styled.nav`
  display: none;
  @media only screen and (min-width: 1024px) {
    grid-column: 5 / 13;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding: 0 0.9375rem;
    font-family: lato, sans-serif;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      list-style-type: none;
      flex-wrap: wrap;
      padding-top: 4px;
    }
  }
`;

const Link = styled.li`
  display: inline-block;
  list-style: none;
  font-size: 0.6875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding: 0 0.6875rem;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    height: 15px;
  }
  a,
  button {
    color: #000000;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    text-transform: uppercase;
    font-family: lato, sans-serif;
    font-size: 0.6875rem;
    padding: 0;
    line-height: 1.25rem;
    &:hover {
      text-decoration: underline;
    }
  }
  &:not(:first-of-type) {
    border-left: 1px solid #ddd;
  }
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Social = styled.a`
  background-color: var(--wc-highlight-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  svg {
    height: 20px;
    width: auto;
    fill: #ffffff;
  }
  &:hover {
    background-color: #ffffff;
    transition: background-color ease-in-out 0.2s;
    svg {
      fill: var(--wc-highlight-color);
      transition: fill ease-in-out 0.2s;
    }
  }
`;

const MobileLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 9 / 13;
  gap: 15px;
  padding: 0 15px;
  box-sizing: border-box;
  @media only screen and (min-width: 640px) {
    grid-column: 10 / 13;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const PhoneLink = styled.a`
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
`;

const BurgerContainer = styled.button`
  display: flex;
  width: 1.875rem;
  height: 2.5rem;
  align-self: center;
  justify-self: end;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

const Burger = styled.div`
  width: ${({ expanded }) => (expanded === true ? "1rem" : "1.25rem")};
  height: 1rem;
  position: relative;
`;

const BurgerBar = styled.span`
  width: 1.875rem;
  height: 2px;
  background: #444;
  position: absolute;
  display: block;
  content: "";
  transform-origin: center center;
  left: 0;
  &:first-child {
    top: ${({ expanded }) => (expanded === true ? "50%" : "0")};
    transform: ${({ expanded }) =>
      expanded === true ? "rotate(45deg)" : "none"};
    transition: all 0.15s;
  }
  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    opacity: ${({ expanded }) => (expanded === true ? "0" : "1")};
  }
  &:last-child {
    top: ${({ expanded }) => (expanded === true ? "50%" : "initial")};
    bottom: ${({ expanded }) => (expanded === true ? "initial" : "0")};
    transform: ${({ expanded }) =>
      expanded === true ? "rotate(-45deg)" : "none"};
    transition: all 0.15s;
  }
`;

export default Navigation;
