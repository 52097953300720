import * as React from "react";
const SvgPlay = (props) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M118 64c0 29.832-24.182 54-54 54S10 93.832 10 64s24.182-54 54-54 54 24.182 54 54Z"
      fill="#F2F2F2"
      fillOpacity={0.3}
    />
    <path
      d="M127 64c0 34.804-28.213 63-63 63S1 98.804 1 64 29.213 1 64 1s63 28.213 63 63Z"
      stroke="#FD7F46"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path d="m48 36 44 28-44 28V36Z" fill="#FD7F46" />
  </svg>
);
export default SvgPlay;
