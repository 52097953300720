import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AddToCalendar, Button, AnonymousForm } from "../../components";
import { useWindowSize } from "../../hooks";

const EventBanner = ({ image, calendarLink }) => {
  const [submissionStatus, setSubmissionStatus] = useState(
    "not-ready-to-submit"
  );
  const { windowWidth } = useWindowSize();
  return (
    <SectionContainer>
      <Banner>
        <ImageWrapper>
          <GatsbyImage image={getImage(image)} alt="Fertility nurse specialist and donor sperm coordinator Ursula Lynch" />
          <ImageOverlay>
            <h3>Ursula Lynch</h3>
          </ImageOverlay>
        </ImageWrapper>
        <Details>
          <h6>Join us at our upcoming webinar:</h6>
          <Title>
            I'd Like To Know <br />
            <i>…How To Go Through It Alone?</i>
          </Title>
          <Date>
            Tuesday, October 24<sup>th</sup> 2023 at 7pm
          </Date>
          <p
            style={
              submissionStatus !== "submit-complete"
                ? { paddingBottom: "2.625rem" }
                : {}
            }
          >
            For our October webinar, fertility nurse specialist and donor sperm coordinator, Ursula Lynch will talk to us all about single motherhood and donor sperm – how do you get started? How do you pick a donor? What is the process? What does it cost?
            <br />
            <br />
            Register here and submit your questions to find out all you need to know.
          </p>
          {submissionStatus === "submit-complete" && (
            <Options>
              <AddToCalendar calendarLink={calendarLink} />
            </Options>
          )}
          <AnimatePresence>
            {submissionStatus === "submit-complete" ? (
              <ThankYou
                initial={{ x: 30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <p>
                  Thank you for submitting your question, <br />
                  we&apos;ll address it at our live webinar
                </p>
              </ThankYou>
            ) : (
              <AnonymousForm
                submissionStatus={submissionStatus}
                setSubmissionStatus={setSubmissionStatus}
                calendarLink={calendarLink}
              />
            )}
          </AnimatePresence>
        </Details>
      </Banner>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 3rem 0;
  width: 100%;
  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Banner = styled.div`
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  grid-column: 1/5;
  margin: 0 1rem;
  p {
    color: var(--wc-dark-text-color);
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  h6 {
    color: var(--wc-dark-text-color);
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 400;
  }
  @media only screen and (min-width: 640px) {
    grid-column: 1 / 9;
    margin: 0 3rem;
    h6 {
      line-height: 2.875rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / 12;
    margin: 0 2rem;
  }
  @media only screen and (min-width: 1920px) {
    grid-column: 3 / 11;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const ImageOverlay = styled.div`
  background: var(--wc-light-background);
  color: var(--wc-highlight-color);
  position: absolute;
  right: 0;
  bottom: 4rem;
  padding: 0.75rem 1rem;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.125rem 1rem 1.125rem;
  @media only screen and (min-width: 1024px) {
    padding: 3rem 4.75rem 2rem 4.75rem;
  }
`;

const Title = styled.h4`
  color: var(--wc-highlight-color);
  font-weight: 700;
  font-size: 2.375rem;
  line-height: 2.875rem;
`;

const Date = styled.h5`
  color: var(--wc-dark-text-color);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.875rem;
  margin-bottom: 1rem;
  sup {
    font-size: 0.875rem;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0 2.625rem 0;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
    gap: 6rem;
  }
`;

const ThankYou = styled(motion.div)`
  align-items: center;
  background: rgba(253, 127, 70, 0.1);
  border: 1px solid #dcd3cb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  width: 100%;
  p {
    padding: 1.75rem 2rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    span {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    p {
      padding: 3.25rem 3rem;
    }
  }
`;

export default EventBanner;
