import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, useInView } from "framer-motion";
import { IdLikeToKnow } from "../../assets/svgs";
import IndividualQuestion from "./IndividualQuestion";

const Questions = ({ questions, setShowCrispCTA }) => {
  const [videoPlaying, setVideoPlaying] = useState("");
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if(isInView) {
      setShowCrispCTA(true);
    } else {
      setShowCrispCTA(false);
    }
  }, [isInView]);
  
  return (
    <Container ref={ref}>
      <InnerContainer>
        <TitleContainer initial="notInView" whileInView="inView" animate="notInView">
          <IdLikeToKnow />
        </TitleContainer>
        <Group>
          {questions?.length > 0 && questions.map((question, index) => (
            <IndividualQuestion
              key={index}
              question={question}
              videoPlaying={videoPlaying}
              setVideoPlaying={setVideoPlaying}
              index={index}
            />
          ))}
        </Group>
      </InnerContainer>
    </Container>
  );
};

const Container = styled(motion.section)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 1rem 0 3rem 0;
  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 0;
  }
`;

const InnerContainer = styled.div`
  grid-column: 1 / 5;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 0.25rem;
  @media only screen and (min-width: 640px) {
    grid-column: 1 / 9;
    padding: 0 3rem;
  }
  @media only screen and (min-width: 1024px) {
    gap: 6rem;
    grid-column: 3 / 11;
    padding: 0;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 0 1rem;
  @media only screen and (min-width: 1024px) {
    padding: 0;
  }
`;

const TitleContainer = styled(motion.div)`
  margin: 0 auto;
  max-width: 680px;
  width: 100%;
  svg {
    filter: drop-shadow(5px 10px 2px rgb(51 48 46 / 0.25));
  }
`;

export default Questions;
