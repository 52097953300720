import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { scrollToAnchor } from "../../utils";

const StickyCallToAction = () => {
  return (
    <StickyButton
      id="sticky-cta"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.075 }}
      whileTap={{ scale: 0.95 }}
      onClick={(e) => {
        e.preventDefault();
        scrollToAnchor("contact-form", {
          duration: 1200,
          offset: -160,
        });
      }}
    >
      Ask Us Your Questions
    </StickyButton>
  );
};

const StickyButton = styled(motion.button)`
  align-items: center;
  background: var(--wc-highlight-color);
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 5px 24px 0px rgba(86, 81, 79, 0.5);
  color: #ffffff;
  display: flex;
  height: 78px;
  justify-content: center;
  padding: 0.25rem;
  position: absolute;
  top: 2rem;
  right: 1rem;
  width: 78px;
  z-index: 100;
  font-size: 0.625rem;
  line-height: 0.875rem;
  text-align: center;
  &:after {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9988 1.50171C9.8759 1.50171 1.66943 9.68293 1.66943 19.7747C1.66943 24.5277 3.49015 28.8564 6.47377 32.1069L6.18966 38.9999L11.4583 35.9463C14.0092 37.2883 16.9144 38.0487 19.9998 38.0487C30.1227 38.0487 38.3292 29.8675 38.3292 19.7757C38.3292 9.68394 30.1217 1.50171 19.9988 1.50171Z' fill='%2356514F' stroke='%23FD7F46' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.957 17.0579V13.5423C15.8898 12.7607 16.8327 12.1851 17.7838 11.8125C18.7359 11.4409 19.7522 11.2542 20.8347 11.2542C22.4761 11.2542 23.7877 11.6826 24.7673 12.5373C25.7469 13.3931 26.2367 14.5463 26.2367 15.997C26.2367 17.002 25.9944 17.8436 25.5086 18.5227C25.0229 19.2019 24.0993 19.9044 22.7378 20.6292C21.8041 21.1134 21.1839 21.4901 20.8764 21.7591C20.5689 22.0291 20.4141 22.3306 20.4141 22.6656C20.4141 22.8707 20.4508 23.094 20.5261 23.3356C20.6004 23.5772 20.7685 23.9498 21.0302 24.4513H19.127C18.5486 23.8006 18.1565 23.2747 17.9518 22.8747C17.7462 22.4748 17.6443 22.0514 17.6443 21.6048C17.6443 21.0464 17.8398 20.5632 18.2319 20.1541C18.6239 19.745 19.4162 19.2333 20.6106 18.6192C21.5627 18.136 22.2012 17.7167 22.5281 17.3634C22.8539 17.0101 23.0179 16.5726 23.0179 16.0518C23.0179 15.4935 22.803 15.0468 22.3743 14.7128C21.9446 14.3778 21.3662 14.2103 20.6391 14.2103C19.7247 14.2103 18.8337 14.4296 17.9661 14.8661C17.0985 15.3036 16.1138 16.0335 15.013 17.0568H14.957V17.0579ZM19.855 31.3169C19.239 31.3169 18.7166 31.1027 18.2879 30.6753C17.8582 30.2479 17.6443 29.7261 17.6443 29.1129C17.6443 28.4998 17.8582 27.9739 18.2879 27.5364C18.7166 27.0999 19.24 26.8806 19.855 26.8806C20.4701 26.8806 20.9976 27.0999 21.4365 27.5364C21.8743 27.9739 22.0943 28.4988 22.0943 29.1129C22.0943 29.7271 21.8743 30.2479 21.4365 30.6753C20.9976 31.1027 20.4711 31.3169 19.855 31.3169Z' fill='white'/%3E%3C/svg%3E");
    height: 20px;
    position: absolute !important;
    right: 0.5rem;
    top: -0.5rem;
    width: 20px;
    z-index: 11;
  }
  @media only screen and (min-width: 1024px) {
    position: fixed;
    top: 8rem;
    bottom: unset;
    padding: 1rem;
    height: 126px;
    right: unset;
    width: 126px;
    left: 2rem;
    font-size: 1rem;
    line-height: 1.25rem;
    &:after {
      height: 37.5px;
      width: 36px;
    }
  }
`;

export default StickyCallToAction;
