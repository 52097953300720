import React from "react";
import styled from "styled-components";
import { Add } from "../../assets/svgs";
import { motion } from "framer-motion";

const DateButton = ({
  calendarLink,
  transparent
}) => {

  return (
    <StyledDateButton
      width="250px"
      transparent={transparent}
      whileHover={{
        scale: 1.025,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <Add />
      <a href={calendarLink} target="_blank" rel="noreferrer">
        Add Date To Calendar
      </a>
    </StyledDateButton>
  );
};

const StyledDateButton = styled(motion.div)`
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  position: relative;
  a {
    align-items: center;
    color: var(--wc-highlight-color);
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0 0.5rem;
    text-transform: uppercase;
    transition: 0.5s;
    width: 100%;
  }
  svg {
    height: 24px;
    width: 24px;
    circle {
      fill: var(--wc-highlight-color);
    }
    path {
      stroke: #fff;
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export default DateButton;
