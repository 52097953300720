import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { LocalContext } from "../../layouts";
import { scrollToAnchor } from "../../utils";
import {
  WaterstonesInitialLogo,
  FacebookF,
  Instagram,
} from "../../assets/svgs";

const Footer = () => {
  const { setIsModalOpen } = useContext(LocalContext);
  return (
    <Container>
      <LogoContainer>
        <WaterstonesInitialLogo />
      </LogoContainer>
      <Locations>
        <p>
          <span>Waterstone Clinic</span>
        </p>
        <p>Dublin | Cork | Kildare | Limerick | Waterford</p>
      </Locations>
      <Contact>
        <ContactButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            scrollToAnchor("contact-form", {
              duration: 1200,
              offset: -160,
            });
          }}
        >
          Contact Us
        </ContactButton>
        <Social
          href="https://www.facebook.com/waterstoneclinicireland/"
          target="_blank"
          rel="noreferrer"
          id="footer-facebook"
        >
          <FacebookF />
        </Social>
        <Social
          href="https://www.instagram.com/waterstoneclinicireland/"
          target="_blank"
          rel="noreferrer"
          id="footer-instagram"
        >
          <Instagram />
        </Social>
      </Contact>
    </Container>
  );
};

const Container = styled(motion.footer)`
  width: 100%;
  padding: 1.5rem 0;
  background: var(--wc-footer-background);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  grid-row-gap: 0.625rem;
  @media only screen and (min-width: 640px) {
    grid-row-gap: 1rem;
  }
  @media only screen and (min-width: 1024px) {
    padding: 3.75rem 0;
    grid-row-gap: 0.625rem;
  }
`;

const LogoContainer = styled.div`
  grid-column: 2 / 3;
  svg {
    width: 62px;
  }
  @media only screen and (min-width: 1720px) {
    grid-column: 3 / 4;
  }
`;

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / 12;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 28px;
  margin-bottom: 2rem;
  p {
    font-weight: 400;
    white-space: nowrap;
    span {
      font-weight: 700;
      font-family: "Mallory", sans-serif;
    }
  }
  @media only screen and (min-width: 640px) {
    grid-column: 4 / 12;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 1024px) {
    grid-column: 3 / 6;
  }
  @media only screen and (min-width: 1720px) {
    grid-column: 4 / 6;
  }
`;

const Contact = styled.div`
  display: flex;
  gap: 20px;
  grid-column: 2 / 12;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    grid-column: 8 / 12;
    justify-self: end;
  }
  @media only screen and (min-width: 1720px) {
    grid-column: 8 / 11;
  }
`;

const ContactButton = styled.button`
  background: var(--wc-highlight-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  border: 1px solid var(--wc-highlight-color);
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.75rem;
  color: white;
  text-transform: uppercase;
  &:hover {
    transform: scale3d(1.025, 1.025, 1.025);
    background: transparent;
    transition: all 200ms ease-in-out;
    span {
      color: var(--wc-highlight-color);
      transition: color ease-in-out 0.2s;
    }
  }
`;

const Social = styled.a`
  background: var(--wc-highlight-color);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--wc-highlight-color);
  svg {
    height: 15px;
    fill: #ffffff;
  }
  &:hover {
    background: transparent;
    transition: background ease-in-out 0.2s;
    svg {
      fill: var(--wc-highlight-color);
      transition: fill ease-in-out 0.2s;
    }
  }
`;

export default Footer;
