import React from "react";
import { useSiteMetadata } from "../../hooks";

const SEO = ({ children, pageSpecificDescription, pageSpecificThumbnail }) => {
  const { title, defaultDescription, siteUrl, twitterUsername, image } =
    useSiteMetadata();
  const thumbnail = pageSpecificThumbnail
    ? `${siteUrl}${pageSpecificThumbnail}`
    : `${siteUrl}${image}`;
  const description = pageSpecificDescription || defaultDescription;
  return (
    <>
      <title>{title}</title>
      <meta name="og:site_name" content={title} />
      <meta name="description" content={description} />
      <meta name="og:locale" content="en-GB" />
      <meta name="og:url" content={siteUrl} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="og:image" content={thumbnail} />
      <meta name="og:image:url" content={thumbnail} />
      <meta name="og:image:secure_url" content={thumbnail} />
      <meta name="og:image:alt" content={title} />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="640" />
      <meta name="image" content={thumbnail} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={thumbnail} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={siteUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="www.twitter.com/waterstoneivf" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="author" content="Agency X" />
      {children}
    </>
  );
};

export default SEO;
