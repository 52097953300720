import React, { useState, useEffect, createContext } from "react";
import { AnimatePresence } from "framer-motion";
import GlobalStyle from "../styles";
import { Footer, Navigation, SEO } from "../components";

export const LocalContext = createContext();

const Layout = ({ children }) => {
  const [colorScheme, setColorScheme] = useState("dark");
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState("787607076");
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [muted, setMuted] = useState(true);

  return (
    <LocalContext.Provider
      value={{
        colorScheme,
        setColorScheme,
        expanded,
        setExpanded,
        isModalOpen,
        setIsModalOpen,
        videoPlaying,
        setVideoPlaying,
        muted,
        setMuted,
        vimeoPlayer,
        setVimeoPlayer
      }}
    >
      <GlobalStyle />
      <SEO />
      <Navigation expanded={expanded} setExpanded={setExpanded} />
      <AnimatePresence>{children}</AnimatePresence>
      <Footer />
    </LocalContext.Provider>
  );
};

export default Layout;
