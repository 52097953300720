import * as React from "react";
const SvgWaterstonesInitialLogo = (props) => (
  <svg
    viewBox="0 0 63 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.619 0c2.596.018 4.442 1.84 4.383 4.323-.06 2.453-1.888 4.115-4.498 4.092-2.591-.023-4.392-1.748-4.378-4.2C53.144 1.72 54.995-.019 57.619 0ZM19.668 43.397h-2.24a.334.334 0 0 1-.315-.21L.023 1.105A.33.33 0 0 1 .34.654h10.91c.142 0 .265.082.316.209 4.282 10.554 8.579 21.135 12.903 31.789a.325.325 0 0 1 0 .245c-1.486 3.406-2.98 6.844-4.484 10.295a.347.347 0 0 1-.316.205ZM24.961.604h10.782c.142 0 .27.086.32.214 4.182 10.553 8.378 21.126 12.62 31.838a.325.325 0 0 1 0 .246l-4.484 10.3a.343.343 0 0 1-.315.199h-2.107a.332.332 0 0 1-.311-.195C40.095 40.213 26.218 5.454 24.64 1.04c-.077-.213.087-.436.325-.436"
      fill="#FD7F46"
    />
  </svg>
);
export default SvgWaterstonesInitialLogo;
