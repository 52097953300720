import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --wc-highlight-color: #FD7F46;
    --wc-light-text-color: #DCD3CB;
    --wc-dark-text-color: #56514F;
    --wc-dark-background: #56514F;
    --wc-light-background: #DCD3CB;
    --wc-footer-background: #353332;
  }

  .crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
      background-color: #FD7F46 !important;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Mallory Book", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--wc-light-text-color);
    font-size: 16px;
    font-display: swap;
    max-width: 100vw;
    background: var(--wc-dark-background);
  }

  h1, h2, h3, h4, h5, h6, p, span, a, ul {
    margin: 0;
    padding: 0;
  }

  h3 {
    font-family: "Mallory", sans-serif;
  }

  button {
    outline: none;
    border: none;
    font-family: 'Mallory', sans-serif;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
`;

export default GlobalStyle;
