import * as React from "react";
import { motion } from "framer-motion";

const up = {
  rest: { y: 0 },
  hover: {
    y: [0, 5, 0],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const SvgReasonFour = (props) => (
  <svg
    viewBox="0 0 80 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  <motion.g 
        variants={up}>
    <path
      d="m2 66.342 11.051-10.767a11.332 11.332 0 0 1 7.907-3.19h8.407c2.39 0 4.684.924 6.375 2.572a7.791 7.791 0 0 0 5.436 2.193h9.045c2.51 0 4.543 1.982 4.543 4.426s-2.035 4.426-4.543 4.426H31.002"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m54.419 59.88 15.955-13.01c1.845-1.503 4.564-1.386 6.265.271a4.442 4.442 0 0 1 0 6.4L53.318 76.26a7.083 7.083 0 0 1-4.942 1.995H24.353a4.25 4.25 0 0 0-2.965 1.196L18.773 82M48.995 20.183l3.575 7.058 7.994 1.131c1.754.248 2.455 2.35 1.185 3.555l-5.785 5.493 1.366 7.757c.3 1.702-1.534 3-3.104 2.196l-7.15-3.662-7.15 3.662c-1.57.804-3.403-.494-3.104-2.196l1.366-7.757-5.785-5.493c-1.269-1.206-.569-3.307 1.186-3.555l7.993-1.131 3.576-7.058c.784-1.549 3.051-1.549 3.836 0h0Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    </motion.g>
    <path
      d="M62.452 8.809h4.892c1.158 0 2.096.914 2.096 2.042v26.554c0 1.128-.938 2.042-2.096 2.042h-4.892M31.702 39.447H18.424c-1.158 0-2.097-.914-2.097-2.042v-4.766"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.111 32.639H11.435c-1.158 0-2.097-.915-2.097-2.043V4.043c0-1.129.939-2.043 2.097-2.043h48.92c1.158 0 2.097.914 2.097 2.043v19.064"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.821 2c0 5.64-4.693 10.213-10.483 10.213M62.452 12.213c-5.79 0-10.483-4.572-10.483-10.213M9.338 22.426c5.79 0 10.483 4.572 10.483 10.213M28.777 24.2c-1.653-1.707-2.666-4.008-2.666-6.54 0-5.264 4.38-9.532 9.784-9.532 3.95 0 7.354 2.28 8.9 5.565"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReasonFour;
