import * as React from "react";
import { motion } from "framer-motion";

const draw = {
  rest: { pathLength: 1, opacity: 1 },
  hover: {
    pathLength: [1, 0, 1],
    opacity: [1, 0, 1],
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};
const SvgReasonThree = (props) => (
  <svg
    viewBox="0 0 84 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.043 80S.753 61.129 12.354 57.355l19.334-6.29v-7.549M52.312 43.516v7.549l19.334 6.29C83.246 61.129 81.957 80 81.957 80M48.445 44.774C57.467 41 58.408 30.822 59.388 22.997c.528-4.227.696-8.769-1.392-12.656-1.908-3.548-5.427-6.014-9.255-7.272a21.686 21.686 0 0 0-13.456 0c-3.841 1.271-7.347 3.724-9.255 7.272-2.088 3.875-1.92 8.429-1.392 12.656.98 7.825 1.92 18.003 10.943 21.777"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.4 19.613c12.889 0 15.467-2.516 16.756-6.29 0 0 0 6.29 6.444 6.29"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <motion.path
      d="M31.689 51.065C31.689 58.613 42 64.903 42 64.903s10.312-6.29 10.312-13.838M27.822 52.323c-5.156 3.774-3.867 10.064-3.867 10.064M29.11 80h3.867v-5.032c0-5.536-3.866-12.58-9.022-12.58-5.156 0-9.023 7.044-9.023 12.58V80H18.8M56.178 52.323c3.867 1.258 3.867 12.58 3.867 12.58v2.516M60.045 80c3.56 0 6.445-2.816 6.445-6.29s-2.885-6.29-6.445-6.29c-3.559 0-6.444 2.816-6.444 6.29S56.486 80 60.045 80Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
        variants={draw}
    />
  </svg>
);
export default SvgReasonThree;
