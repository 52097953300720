import React from "react";
import { motion } from "framer-motion";

const draw = {
  rest: { pathLength: 1, opacity: 1 },
  hover: {
    pathLength: [1, 0, 1],
    opacity: [1, 0, 1],
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const SvgReasonOne = (props) => {
  return (
    <motion.svg
      viewBox="0 0 90 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.269 41.458a5.905 5.905 0 0 0-7.345-2.152l-2.313 1.036s-.617-7.112-5.597-7.284l-2.559.06M42.195 43.628l2.164 4.55c1.023 2.258 3.669 3.252 5.905 2.22l2.025-.935"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m38.075 51.258 3.614-6.598c1.605-2.978 2.222-6.46 1.418-9.75a9.25 9.25 0 0 0-.257-.879c-.624-1.803-2.51-4.336-4.806-5.197-5.906-2.22-11.059 2.635-11.059 2.635a2.22 2.22 0 0 0-2.954-1.11l-.468.217c-2.164.998-3.149 3.529-2.285 5.765 1.238 3.203 2.926 7.809 7.5 7.329"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.335 27.458c1.1-7 7.615-12.183 15.078-11.042 5.559.85 10.063 5.365 10.944 10.969.832 5.299-1.443 10.122-5.268 12.929-.995.728-1.657 1.834-1.727 3.073a75.146 75.146 0 0 1-.11 1.858c-.524 2.898-1.755 5.607-3.388 8.036C60.463 59.823 53.219 64.3 45.569 65.77c-4.196.805-8.673.7-12.567-1.074-3.548-1.617-7.008-5.072-7.58-9.121-.618-4.365 1.043-9.027 3.741-12.408l3.555-4.455"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <motion.path
        d="M51.946 5.3c2.91-2.03 7.224-3.042 11.097-3.042 13.788 0 24.968 11.2 24.968 26.6 0 36.4-43 57.4-43 57.4s-43-21-43-57.4c0-15.4 11.18-26.6 24.967-26.6 9.71 0 16.646 5.6 16.646 12.6s-3.815 9.975-1.172 18.26"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={draw}
      />
    </motion.svg>
  );
};

export default SvgReasonOne;
