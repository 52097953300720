import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { ContactForm } from "../../components";

const Contact = () => {
  const [submissionStatus, setSubmissionStatus] = useState(
    "not-ready-to-submit"
  );
  return (
    <Container>
      <InnerContainer id="contact-form">
        <AnimatePresence>
          {submissionStatus === "submit-complete" ? (
            <Description
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h3>Thank you!</h3>
              <p>
                We will give you a call within 48 hours.
                <br />
                Waterstone Clinic provides free telephone advice and we can book
                appointments on the same call if you wish.
              </p>
            </Description>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ width: '100%'}}
            >
              <Description>
                <h3>Get In Touch</h3>
                <p>Enter your details and we'll get back to you</p>
              </Description>
              <ContactForm
                submissionStatus={submissionStatus}
                setSubmissionStatus={setSubmissionStatus}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  position: relative;
  z-index: 25;
`;

const InnerContainer = styled.div`
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 25;
  max-width: 1100px;
  margin: 3rem 1rem;
  padding: 2rem 1rem;
  min-height: 300px;
  @media only screen and (min-width: 640px) {
    margin: 3rem;
  }
  @media only screen and (min-width: 1024px) {
    margin: -3rem auto 3rem auto;
    padding: 2rem 3rem;
  }
`;

const Description = styled(motion.div)`
  display: flex;
  flex-direction: column;
  font-family: "Mallory", sans-serif;
  font-style: normal;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  h3 {
    color: var(--wc-highlight-color);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 3.425rem;
  }
  p {
    color: var(--wc-dark-text-color);
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    max-width: 220px;
  }
  @media only screen and (min-width: 1024px) {
    h3 {
      font-size: 38px;
    }
    p {
      max-width: 480px;
    }
  }
`;

export default Contact;
