import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const DropdownNavigation = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };
  return (
    <Container variants={container} initial="hidden" animate="show">
      <Links>
        <Link>
          <a
            href={`https://waterstoneclinic.ie/fertility-tests/`}
            id="navigation-fertility-tests"
          >
            Fertility Tests
          </a>
        </Link>
        <Link>
          <a
            href={`https://waterstoneclinic.ie/treatments-care/`}
            id="navigation-treatments"
          >
            Treatments
          </a>
        </Link>
        <Link>
          <a
            href={`https://waterstoneclinic.ie/paying-for-fertility-treatment/our-price-list/`}
            id="navigation-costs"
          >
            Costs
          </a>
        </Link>
        <Link>
          <a
            href={`https://waterstoneclinic.ie/nationwide-clinics-2/`}
            id="navigation-clinics"
          >
            Clinics
          </a>
        </Link>
        <Link>
          <a
            href="https://portal.waterstoneclinic.ie/"
            target="_blank"
            rel="noreferrer"
            id="navigation-portal"
          >
            Patient Portal
          </a>
        </Link>
      </Links>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  top: 79px;
  left: 0;
  display: block;
  background: #ffffff;
  padding: 0 0.625rem;
  width: 100%;
  padding-bottom: 1.375rem;
  z-index: 5;
  height: auto;
  box-sizing: border-box;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 1px;
`;

const Link = styled.li`
  box-sizing: border-box;
  width: 100%;
  list-style: none;
  font-size: 0.6875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding: 1rem 1.375rem;
  background: rgba(159, 160, 161, 0.2);
  a {
    color: #42495c;
    white-space: nowrap;
    text-align: center;
  }
`;

export default DropdownNavigation;
