import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Play } from "../../assets/svgs";

const VideoPlayer = ({
  title,
  vimeoCode,
  paddingTop = "56.25%",
  videoPlaying,
  setVideoPlaying,
}) => {
  return (
    <AspectRatioWrapper paddingTop={paddingTop} className="no-drag">
      <iframe
        allow="autoplay; fullscreen"
        title={title}
        allowFullScreen
        frameBorder="0"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        height="100%"
        src={`https://player.vimeo.com/video/${vimeoCode}?transparent=true&autopause=0&autoplay=${
          videoPlaying === vimeoCode
        }`}
        width="100%"
      />
      {videoPlaying !== vimeoCode && (
        <Underlay
          key={vimeoCode}
          onClick={() => setVideoPlaying(vimeoCode)}
          whileHover={{ scale: 1.075 }}
          initial={{
            scale: 1,
            x: "-50%",
            y: "-50%",
          }}
          whileTap={{ scale: 0.95 }}
          className="no-drag"
        >
          <Play />
        </Underlay>
      )}
    </AspectRatioWrapper>
  );
};

const AspectRatioWrapper = styled.div`
  position: relative;
  padding-top: ${({ paddingTop }) => paddingTop};
  width: 100%;
`;

const Thumbnail = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  user-select: none;
`;

const Underlay = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 64px;
    width: 64px;
  }
  @media only screen and (min-width: 640px) {
    svg {
      height: 100px;
      width: 100px;
    }
  }
  @media only screen and (min-width: 1024px) {
    svg {
      height: 126px;
      width: 126px;
    }
  }
`;

export default VideoPlayer;
