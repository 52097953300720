import * as React from "react";
const SvgBookIconBlue = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <g fill="#32B7C8" fillRule="evenodd">
      <path d="M10.083 16.5h-8.25V7.333H16.5v2.75h1.834v-5.5c0-1.01-.822-1.833-1.833-1.833h-.917V0H13.75v4.583h-.917V2.75H6.417V0H4.583v4.583h-.916V2.75H1.833A1.836 1.836 0 0 0 0 4.583V16.5c0 1.011.822 1.833 1.833 1.833h8.25V16.5z" />
      <path d="m16.5 11 1.571 3.929H22l-3.399 2.869L20.428 22 16.5 19.402 12.571 22l1.828-4.202L11 14.928h3.928z" />
    </g>
  </svg>
);
export default SvgBookIconBlue;
