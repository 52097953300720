import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { VideoPlayer, Button } from "../../../components";
import { scrollToAnchor } from "../../../utils";

const IndividualQuestion = ({
  question,
  videoPlaying,
  setVideoPlaying,
  index,
}) => {
  return (
    <Wrapper widerMargin>
      <ImageContainer>
        <VideoPlayer
          vimeoCode={question.vimeoCode}
          paddingTop="56.097560975609756%"
          title={question.title}
          thumbnail={question.thumbnail}
          videoPlaying={videoPlaying}
          setVideoPlaying={setVideoPlaying}
        />
      </ImageContainer>
      <Title>{question.title}</Title>
      <Body bullets={question.points[0].text.length < 150}>
        {question.points.length > 0 &&
          question.points.map((point) => (
            <p>
              {point.text.length < 150 && <span>●</span>}
              {point.text}
            </p>
          ))}
      </Body>
      <Button
        id={`question-${index + 1}-cta`}
        whileHover={{
          scale: 1.025,
        }}
        whileTap={{ scale: 0.9 }}
        style={{
          fontWeight: "400",
        }}
        onClick={(e) => {
          if (question.actionLink) {
            window.open(`${question.actionLink}`, "_blank");
          } else {
            e.preventDefault();
            scrollToAnchor("contact-form", {
              duration: 1200,
              offset: -160,
            });
          }
        }}
      >
        {question.callToAction}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margintop: ${({ widerMargin }) => (widerMargin ? "3rem" : "0")};
`;

const ImageContainer = styled(motion.div)`
  width: 100%;
  position: relative;
`;

const Title = styled.h3`
  color: var(--wc-highlight-color);
  font-weight: 700;
  font-size: 2.375rem;
  line-height: 2.875rem;
  text-transform: capitalize;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem 1.5rem;
  margin-left: ${({ bullets }) => (bullets ? "1.25rem" : "0")};
  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--wc-light-text-color);
    position: relative;
    span {
      color: var(--wc-highlight-color);
      left: -1.25rem;
      position: absolute;
      top: 0;
      font-size: 1.25rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-rows: ${({ bullets }) => (bullets ? "1fr 1fr" : "1fr")};
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 2rem;
    margin-left: 0;
  }
`;

export default IndividualQuestion;
