import * as React from "react";
import { motion } from "framer-motion";

const turn = {
  rest: { rotate: 0 },
  hover: {
    rotate: [0, -5, 0],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const SvgReasonTwo = (props) => (
  <svg
    viewBox="0 0 74 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 82C2 78.4387 5.01538 75.5484 8.73077 75.5484H65.2692C68.9846 75.5484 72 78.4387 72 82"
      stroke="white"
      stroke-width="3"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M20.8462 72.9678V66.5162C20.8462 61.5226 25.0597 57.4839 30.2693 57.4839C35.4789 57.4839 39.6924 61.5226 39.6924 66.5162V72.9678"
      stroke="white"
      stroke-width="3"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M20.8462 68.5162C10.0904 66.1549 2 56.8775 2 45.871C2 33.5355 12.1769 23.3549 24.8846 22.6839"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.8847 58.7097C18.1001 58.0517 12.7693 52.5291 12.7693 45.871C12.7693 38.7742 18.827 32.9678 26.2308 32.9678H31.6154"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.6923 66.5161H62.5769C64.0577 66.5161 65.2692 67.6774 65.2692 69.0968"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.2692 69.0967C31.7561 69.0967 32.9615 67.9413 32.9615 66.5161C32.9615 65.0908 31.7561 63.9354 30.2692 63.9354C28.7823 63.9354 27.5769 65.0908 27.5769 66.5161C27.5769 67.9413 28.7823 69.0967 30.2692 69.0967Z"
      fill="white"
    />
    <motion.g variants={turn}>
      <path
        d="M39.6923 30.3872C41.1792 30.3872 42.3846 29.2318 42.3846 27.8065C42.3846 26.3813 41.1792 25.2259 39.6923 25.2259C38.2053 25.2259 37 26.3813 37 27.8065C37 29.2318 38.2053 30.3872 39.6923 30.3872Z"
        fill="white"
      />
      <path
        d="M45.0769 44.5806H41.0385C40.2981 44.5806 39.6923 44 39.6923 43.2903V36.8387"
        stroke="white"
        stroke-width="3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M39.6923 18.7742V12.3226C39.6923 10.9032 40.9038 9.74194 42.3846 9.74194H62.5769C64.0577 9.74194 65.2692 10.9032 65.2692 12.3226V43.2903C65.2692 44 64.6635 44.5807 63.9231 44.5807H53.1538"
        stroke="white"
        stroke-width="3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M47.7693 4.58065V2H57.1924V9.74194"
        stroke="white"
        stroke-width="3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M45.0769 2H59.8846"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.7308 44.5807V51.0323H61.2308V44.5807"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.1924 51.0322V60.0645H47.7693V51.0322"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.5386 18.7742V35.5484"
        stroke="white"
        stroke-width="3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M39.6923 36.8387C44.8966 36.8387 49.1154 32.7948 49.1154 27.8065C49.1154 22.8181 44.8966 18.7742 39.6923 18.7742C34.4881 18.7742 30.2693 22.8181 30.2693 27.8065C30.2693 32.7948 34.4881 36.8387 39.6923 36.8387Z"
        stroke="white"
        stroke-width="3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </motion.g>
  </svg>
);
export default SvgReasonTwo;
