import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { hexToRGB } from "../../utils";

const Button = ({
  children,
  onClick,
  disabled,
  style,
  type = "button",
  whileFocus,
  whileHover,
  whileTap,
  width,
  variants,
  onMouseEnter,
  onMouseLeave,
  id
}) => (
  <Wrapper
    animate="animate"
    disabled={disabled}
    exit="exit"
    initial="initial"
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={style}
    type={type}
    variants={variants}
    whileFocus={whileFocus}
    whileTap={whileTap}
    whileHover={whileHover}
    width={width}
    id={id}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled(motion.button)`
  align-items: center;
  background: ${({ disabled }) => disabled ? hexToRGB({ color: '#FD7F46', alpha: 0.5 }) : 'var(--wc-highlight-color)'};
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 0.875rem 0.25rem;
  font-style: normal;
  font-size: 1rem;
  cursor: ${({ disabled }) => (disabled === true ? "not-allowed" : "pointer")};
  @media only screen and (min-width: 1024px) {
    padding: 0.875rem 0.75rem;
    width: max-content;
  }
`;

export default Button;
